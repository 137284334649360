import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Login } from "./Login";
import { Signup } from "./Signup";
import { Skillpage } from "./components/Skillspage";
import { Uploadpage } from "./components/Uploadpage";
import { Dashboard } from "./components/Sectionpage";
import Profilesection from "./components/Profilesection";
import Applicationsection from "./components/Applicationsection";
import Endpage from "./components/Endpage";
import ForgetPassword from "./components/ForgetPassword";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Viewjobs from "./components/Viewjobs";
import JobDetails from "./screens/jobDetails";
import RecommendedSaved from "./screens/RecommendedSaved";
import Changepassword from "./components/Changepassword";
import AiFullPage from "./screens/aIrecommendedFullPage";
import { useCookies } from "react-cookie";
import LinkedinPopup from "./components/LinkedinPopup";

function ProtectedRoute() {
  const [open, setOpen] = useState(false);
  const [flag, _flag] = useState(false);
  const nav = useNavigate();

  const location = useLocation();

  const permanentOpenAPI = [
    "/about",
    "/contactus",
    "/sitemaphtml",
    "/newsletter/unsubscribe",
    "/forgetpassword",
    "/privacypolicy",
    "/termscondition",
    "/securitycheaker",
    "/bewarefruad",
    "/gdpr",
    "/mobilesearch",
    "newsletter/unsubscribe",
  ];
  const openAllAPI = ["/", "/jobsearch"];
  const unprotectedAPI = ["/login", "/signup", "/linkedin"];
  const protectURL = [
    "/skillpage",
    "/uploadpage",
    "/dashboard",
    "/profilesection",
    "/appliedJobs",
    "/viewdetailpage",
    "/Viewjobs",
    "/prefferedJobs",
    "/Changepassword",
    "/AiFullPage",
  ];
  const [cookies, setCookie] = useCookies([]);

  const protectedHandler = () => {
    const cookie =
      document.cookie.includes("_secure_ARJ_") ||
      document.cookie.includes("_secure_ref");

    if (cookie) {
      _flag(true);
      if (permanentOpenAPI.includes(location.pathname)) {
        // nav('/about')
      }
      if (openAllAPI.includes(location.pathname)) {
        nav("login");
      } else if (
        location.pathname.split("/")[1] === "jobsearch" ||
        location.pathname.split("/")[1] === "user" ||
        location.pathname.split("/")[1] === "jobsearch" ||
        location.pathname.split("/")[1] === "jobDetail" ||
        location.pathname.split("/")[1] === "recommendedJobs" ||
        location.pathname.split("/")[1] === "prefferedJobs"
      ) {
        // nav("#");
      } else if (unprotectedAPI.includes(location.pathname)) {
        nav("dashboard");
      } else if (
        !protectURL.includes(location.pathname) &&
        !permanentOpenAPI.includes(location.pathname)
      ) {
        nav("page404");
      }
    } else {
      _flag(false);
      if (
        location.pathname.split("/")[1] === "jobsearch" ||
        location.pathname.split("/")[1] === "user" ||
        location.pathname.split("/")[1] === "jobDetail"
      ) {
        // nav("#");
      } else if (protectURL.includes(location.pathname)) {
        nav("/login");
      } else if (
        !openAllAPI.includes(location.pathname) &&
        !unprotectedAPI.includes(location.pathname) &&
        !permanentOpenAPI.includes(location.pathname)
      ) {
        nav("page404");
      }
    }
  };

  useEffect(() => {
    protectedHandler();
  }, [flag, location.pathname]);

  useEffect(() => {
    if (Number(localStorage.getItem("linkedIn")) === 0) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("linkedIn", '1');
  };

  return (
    <div>
      {flag ? (
        <>
          {" "}
          <LinkedinPopup open={open} onClose={handleClose} />
          <Routes>
            <Route exact path="/skillpage" element={<Skillpage />} />
            <Route exact path="/uploadpage" element={<Uploadpage />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/profilesection" element={<Profilesection />} />
            <Route exact path="/appliedJobs" element={<Applicationsection />} />
            <Route
              exact
              path="/viewdetailp/forgetpasswordage"
              element={<Endpage />}
            />
            <Route path="/Viewjobs" element={<Viewjobs />} />
            <Route path="/recommendedJobs" element={<AiFullPage />} />
            <Route exact path="/prefferedJobs" element={<RecommendedSaved />} />
            <Route exact path="/Changepassword" element={<Changepassword />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/linkedin" element={<LinkedInCallback />} />
        </Routes>
      )}
    </div>
  );
}

export default ProtectedRoute;
