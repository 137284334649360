import React, { useState } from "react";
import "./Changepassword.css";
import { Button, IconButton, TextField, useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Helmet } from "react-helmet";


const Changepassword = ({ setToggleChangePasswordPage }) => {
  const nav = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:430px)');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showNewPasswordError, setShowNewPasswordError] = useState(false);
  const [error, setError] = useState("");
  const regexPass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const handleForgetPW = () => {
    nav("/forgetpassword");
  };

  const handleback = () => {
    setToggleChangePasswordPage(false);
  };

  const handleOldPasswordChange = (e) => {
    const { value } = e.target;
    setOldPassword(value);

    setErrorOldPassword("");
  };

  const handleNewPasswordChange = (e) => {
    const { value } = e.target;
    setNewPassword(value);
    setErrorNewPassword("");
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setErrorConfirmPassword("");
  };

  const handleSave = () => {

    // Reset previous error messages
    setErrorOldPassword("");
    setErrorNewPassword("");
    setErrorConfirmPassword("");

    // Validate the form
    if (oldPassword.trim() === "") {
      setErrorOldPassword("Please enter the old password");
      return;
    }
    if (newPassword.trim() === "") {
      setErrorNewPassword("Please enter the new password");
      return;
    }
    if (!regexPass.test(newPassword)) {
      setErrorNewPassword("Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols. (ex: 22aAA22@)");
      return;
    }
    if (oldPassword.trim() === newPassword.trim()) {
      setErrorNewPassword("New password cannot be the same as the old password");
      return;
    }

    if (confirmPassword.trim() === "") {
      setErrorConfirmPassword("Please confirm the new password");
      return;
    }
    if (newPassword.trim() !== confirmPassword.trim()) {
      setErrorConfirmPassword("Passwords do not match");
      return;
    } else {
      const payload = {
        token: document.cookie,
        oldPassword: oldPassword,
        newPassword: confirmPassword,
      };

      axios
        .post(`change-password`, payload)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Password Changed Successfully", {
              hideProgressBar: true,
              closeOnClick: true,
            });
            // setOldPassword("");
            // setNewPassword("");
            // setConfirmPassword("");
            // setErrorNewPassword("");
            setShowOldPassword(false);
            setShowNewPassword(false);
            setShowConfirmPassword(false);
            setError("");
            localStorage.clear();
            const d = new Date();
            let expires = "expires=" + d.toUTCString();
            document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
            document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
            document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
            nav("/login");
          } else {
            setError("Incorrect old password");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>Change Your Password – Secure Your Jobeze Account</title>
        <meta name="title" content='Change Your Password – Secure Your Jobeze Account' />
        <meta name="description" content="Update your password for enhanced security. Change your Jobeze account password easily and keep your job search data safe." />
      </Helmet>
      <div
        className="bg-white fx change-web-pass"

      >
        <div
          className="px-4 pt-2 fs-4 fw-bold change-web"
          onClick={handleback}
          style={{ cursor: "pointer" }}
        >
          <ArrowBackIosNewIcon fontSize="small" sx={{ marginRight: 1 }} />
          Change Password
        </div>
        <div
          className="px-4 pt-2 fs-4 fw-bold change-mobile"
          onClick={() => nav('/profilesection')}
          style={{ cursor: "pointer" }}
        >
          <ArrowBackIosNewIcon fontSize="small" sx={{ marginRight: 1 }} />
          Change Password
        </div>
        <hr />
        <div className="py-2 px-4 change-mobile-handle">
          <div className="pb-4">
            <TextField
              variant="outlined"
              name=""
              sx={{ width: isSmallScreen ? "100%" : "40ch" }}
              label="Old Password"
              value={oldPassword}
              onChange={handleOldPasswordChange}
              error={errorOldPassword !== "" || error}
              helperText={errorOldPassword || error}
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 600
                }
              }}
            />
          </div>
          <div className="pb-4">
            <TextField
              variant="outlined"
              name=""
              sx={{ width: isSmallScreen ? "100%" : "40ch" }}
              label="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              onFocus={() => setShowNewPasswordError(false)}
              error={showNewPasswordError && !regexPass.test(newPassword) || errorNewPassword !== ""}
              helperText={
                errorNewPassword !== "" ? errorNewPassword :
                  showNewPasswordError && !regexPass.test(newPassword)
                    ? "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols. (ex: 22aAA22@)"
                    : ""

              }
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 600
                }
              }}
            />
          </div>
          <div className="pb-4">
            <TextField
              variant="outlined"
              name=""
              sx={{ width: isSmallScreen ? "100%" : "40ch" }}
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onFocus={() => setShowNewPasswordError(true)}
              error={errorConfirmPassword !== ""}
              helperText={errorConfirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 600
                }
              }}
            />
          </div>
          <div className="pb-4" style={{ cursor: "pointer" }}>
            <p
              onClick={handleForgetPW}
              className="text-primary text-decoration-underline fw-bold"
            >
              Forgot Password
            </p>
          </div>
        </div>
        {/* <div xl></div> */}

        <div style={{ boxSizing: "border-box" }} className="px-4 py-2">
          <Button
            onClick={handleSave}
            variant="contained"
            size="large"
            className={`px-4 fw-bold ${isSmallScreen ? "buttonhold" : ""} `}
            fullWidth={isSmallScreen ? true : false}
          >
            {" "}
            Save{" "}
          </Button>
        </div>
        <div style={{ boxSizing: "border-box" }} className={`${isSmallScreen ? "" : "pb-5"}`}></div>
      </div>
    </>
  );
};

export default Changepassword;
