import React from "react";
import "./Sectionpage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FooterZ from "./FooterZ";
import Button from "@mui/material/Button";
import Homeheader from "./homeheader";
import Shimmercard from "./Shimmercard";
import CardOfRecommended from "./cardOfRecommended.js";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgress from "@mui/material/LinearProgress";
import AppliedCardUI from "./appliedCard.js";
import Recommendedrawer from "./Recommendeddrawer.js";
import ResendBackdrop from "../ResendBackdrop.js";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { getProfile } from "../redux/modules/userSlice.js";
import { useAppDispatch } from "../redux/hooks.js";
import { Helmet } from "react-helmet";

export function Dashboard() {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [jobOrder_id, setJobOrderId] = useState();
  const [percentage, setPercentage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  // const [page, setPage] = useState(1);
  // const [checkData, setCheckData] = useState([]);
  const [name, setName] = useState("");
  // const [isOpen, setIsOpen] = useState(true);
  // const [errorData, setErrorData] = useState(false);
  const [src, setSrc] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingApplied, setLoadingApplied] = useState(false);
  const [skills, setSkills] = useState([]);
  const [subscriber, setSubscriber] = useState(0);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updateResponse, setUpdateResponse] = useState(null);
  const [updateSkilledDrawer, setUpdateSkilledDrawer] = useState(false);

  const [date_modified, setDate_modified] = useState("");

  const [cookies, setCookie] = useCookies([]);

  const handleBackP = () => {
    nav("/profilesection");
  };

  const shrimmerLoading = async () => {
    // let page = 1
    try {
      // setloading(true);
      window.scrollTo(0, 0);
      const response = await axios.get(
        `recommend-jobs/${localStorage.getItem("id")}/page-no/1`,
        // `recommend-jobs/14211/page-no/1`,
        // { token: document.cookie }
        {
          headers: {
            authorization: document.cookie,
            "If-None-Match": ' W/"0-0 ',
            Pragma: "no-cache",
            "Cache-Control": "no-cache",
          },
        }
      );
      // page =  page + 1;
      setData(response?.data?.message.data.slice(0, 6));
      setJobOrderId(response?.data?.message?.data.joborder_id);
      // setIsOpen(false);
      setTotalPage(response?.data?.message?.total);
      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error) {
      setloading(false);
      // setIsOpen(false);
      // setErrorData(true);
    }
  };

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const waitForToken = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  };

  useEffect(() => {
    if (cookies._secure_ARJ_ || cookies._secure_ref) {
      const checkRefreshToken = async () => {
        const tokenReady = await waitForToken();
        if (tokenReady) {
          setShouldFetchData(true);
        }
      };
      checkRefreshToken();
    }
  }, [cookies]);

  useEffect(() => {
    if (shouldFetchData) {
      const fetchData = async () => {
        await ProfileData();
        AppliedCard();
      };
      fetchData();
    }
  }, [shouldFetchData]);


  useEffect(() => {
    let attempt = 1; // Set the initial attempt value
    if (!updateSkilledDrawer) {
      setloading(true);
    }
    const UpdateAIrcmdJob = async () => {
      try {
        const response = await axios.get("/recommendation-update-status", {
          headers: { authorization: document.cookie },
        });
        const responseData = await response.data.message;
        if (updateSkilledDrawer) {
          setUpdateLoader(true);
        }
        setUpdateResponse(responseData);
        if (responseData[0]?.update_status !== 0) {
          setTimeout(async () => {
            try {
              if (updateSkilledDrawer) {
                setUpdateLoader(true);
              }
              window.scrollTo(0, 0);
              const response = await axios.get(
                `recommend-jobs/${localStorage.getItem("id")}/page-no/1`,
                {
                  headers: {
                    authorization: document.cookie,
                    "If-None-Match": ' W/"0-0 ',
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }
              );
              setData(response?.data?.message.data.slice(0, 6));
              setJobOrderId(response?.data?.message?.data.joborder_id);
              setTotalPage(response?.data?.message?.total);
              setUpdateSkilledDrawer(false);
              setloading(false);
            } catch (error) {
              setUpdateLoader(false);
              setloading(false);
              // setIsOpen(false);
              // setErrorData(true);
            }
          }, 2000);
          setTimeout(() => {
            setUpdateLoader(false);
          }, 4000);
          clearInterval(intervalId);
        } else {
          setUpdateLoader(true);
        }
        // setTimeout(() => {
        //   setUpdateLoader(false);
        // }, 4000);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    //----this line

    // }
    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(UpdateAIrcmdJob, 1000);

    // Clean up the interval
    return () => clearInterval(intervalId);
  }, [updateSkilledDrawer]);

  const handleClick = (item) => {
    // if (isSmallScreen) {
    // user/applied/all-job/page-no/1?shortlisted=false
    nav(`/jobDetail/${item.joborder_id}`);
    // }
    // else {
    // nav("/recommendedJobs", { state: { job_id: jobOrder_id } });
    // }
  };

  // const handleSearch = () => {
  //   setSearchClicked(true);
  //   // if (checkData.length === 0) return;

  //   const paramss = new URLSearchParams();
  //   if (skill) paramss.append('search', skill);
  //   paramss.append('location', location ? location : 'World Wide');
  //   // paramss.append('jobRole', checkData);
  //   // nav(`/jobsearch/allsearchdata${paramss ? '?' + paramss : ''}`, { state: { pageData: getPageNumber, category: checkData, isDashboard: true } })
  // }

  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  // const isMediumScreen = useMediaQuery('(max-width: 1200px)');

  const [openProfileAlert, setOpenProfileAlert] = useState(true);
  const [AppliedData, setAppliedData] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(page);
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const AppliedCard = () => {
    setLoadingApplied(true);
    axios
      .get(`user/applied/all-job/page-no/1?shortlisted=false`, {
        // token: document.cookie,
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        // Set the applied data
        setAppliedData(...AppliedData, response.data.message.data.slice(0, 3));
        setLoadingApplied(false);
        // setIsOpen(false);
      })
      .catch((err) => {
        // Close the modal
        // setIsOpen(false);
        setLoadingApplied(false);
      });
  };
  const [onclickdrawer, setonclickdrawer] = useState(false);

  const handledrawer = () => {
    setonclickdrawer(true);
  };
  const handleCloseDrawer = async () => {
    setonclickdrawer(false);
    await ProfileData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    // setIsOpen(true);
    // setloading(true);
    // await shrimmerLoading();
    // setIsOpen(false);
    // setloading(false);
  };

  const ProfileData = async () => {
    try {
      const profileResponse = await axios.get(`user/dashboard/details`, {
        headers: { authorization: document.cookie },
      });
      // console.log(document.cookie,"token");
      setSkills(profileResponse?.data?.message?.details?.skill);
      setPercentage(profileResponse?.data?.message?.complete_profile);
      setName(profileResponse?.data?.message?.details?.name);
      setDate_modified(profileResponse.data.message.details.date_modified);
      setSubscriber(profileResponse?.data?.message?.details?.isSubscribe);
      localStorage.setItem(
        "name",
        profileResponse?.data?.message?.details?.name
      );
      localStorage.setItem(
        "email",
        profileResponse?.data?.message?.details?.email
      );
      setSrc(profileResponse?.data?.message?.details?.profile_path);
      dispatch(
        getProfile(profileResponse?.data?.message?.details?.profile_path)
      );
    } catch (error) {
      // setErrorData(true);
      // setIsOpen(false);
      // setloading(false);
    }
  };

  const handleSubscribe = async () => {
    if (subscriber === 0) {
      try {
        const response = await axios.post(`subscribe/user`, {
          email: localStorage.getItem("email"),
        });

        toast.success("Subscribed Successfully");
        setSubscriber(1);
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Jobeze Dashboard – Manage Your Job Applications</title>
        <meta name="title" content='Jobeze Dashboard – Manage Your Job Applications' />
        <meta name="description" content="Access your Jobeze dashboard to manage job applications, track your progress, and utilize our AI job assistant for efficient job search automation." />
      </Helmet>
      {/* {isOpen && <ResendBackdrop />} */}
      <Homeheader src={src} />
      <Collapse in={openProfileAlert} sx={{ width: "100%" }}>
        {percentage === "100" ? null : (
          <Alert
            severity="info"
            sx={{ px: { xs: 1, md: 10 } }}
            onClose={() => {
              setOpenProfileAlert(false);
            }}
          >
            Completing your profile gives you higher chances of getting noticed
            by recruiters.
          </Alert>
        )}
      </Collapse>
      <div
        className="fx"
        style={{
          background: "#F5F5F5",
          padding: isSmallScreen ? "" : "40px 48px 40px 48px",
        }}
      >
        <Container maxWidth="xxl" className="p-0">
          <Box mb={3}>
            <Grid
              container
              maxWidth={"xxl"}
              gap={2}
              justifyContent={"space-between"}
              flexDirection={isSmallScreen ? "column-reverse" : ""}
            >
              <Grid
                item
                xs={isSmallScreen ? 12 : 9}
                className="rounded-3 px-3 py-3 bg-white"
              >
                {updateLoader ? (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ height: "100%" }}
                  >
                    <Typography
                      className="position-relative"
                      sx={{ height: "150px" }}
                    >
                      <Typography className="sampleLoading" alt="Loading spinner for job search results" title="Loading Job Search Results on Jobeze"></Typography>
                      <Typography
                        textAlign={"center"}
                        gutterBottom
                        className="fw-bold fx"
                        variant="h5"
                      >
                        Please wait while we update your jobs . . .
                      </Typography>
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      className="fw-bold"
                      gutterBottom
                    >
                      <Typography variant="h5" className="fw-bold">
                        Recommended Jobs
                      </Typography>
                      {data.length > 0 ? (
                        <Button
                          variant="text"
                          className="fw-bold"
                          onClick={() =>
                            nav("/prefferedJobs?job=recommendedJobs")
                          }
                          sx={{
                            textDecoration: "underline",
                            textTransform: "unset",
                          }}
                        >
                          See all
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Typography>
                    <Box className="">
                      {loading ? ( // Check if data is loading
                        // Show shimmer cards
                        <Box className="row row-cols-xl-3">
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                          <Box className="py-2">
                            <Shimmercard />
                          </Box>
                        </Box>
                      ) : data.length > 0 && data !== null ? ( // Check if data is available
                        // Show recommended cards
                        <Box className="row row-cols-xl-3">
                          {data.map((item, index) => (
                            <Box key={index} className=" py-2">
                              <CardOfRecommended
                                data={item}
                                selected={{}}
                                handleClick={handleClick}
                              />
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        // Show no recommended jobs message
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ height: "45vh" }}
                        >
                          <Box textAlign={"center"}>
                            <Typography gutterBottom textAlign={"center"}>
                              <img
                                className="img-fluid"
                                width={90}
                                src="./recommendedbriefcase.svg"
                                alt="Recommended job opportunities on Jobeze"
                                title="Recommended Jobs for You"
                              />
                            </Typography>
                            <Typography
                              variant="h5"
                              className="fw-bold"
                              gutterBottom
                            >
                              No recommended jobs
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body1"
                              color={"text.secondary"}
                            >
                              Add more skills to your profile to get job
                              recommendations
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={isSmallScreen ? 12 : 2.8}
                sx={{ overflow: "hidden" }}
              >
                <Box
                  className="rounded-2 bg-white mb-3 h-100 d-flex flex-column justify-content-evenly align-items-center py-3 px-2 "
                  sx={{ gap: isSmallScreen ? 1.5 : 1.3 }}
                >
                  <Typography
                    variant="h1"
                    sx={{ fontSize: "24px" }}
                    textAlign={"center"}
                    className="fw-bold"
                    color="primary"
                  >
                    My Profile
                  </Typography>
                  {src ? (
                    <img
                      style={{ width: "175px", height: "175px" }}
                      alt="Profile"
                      src={src}
                      loading="lazy"
                      referrerPolicy="no-referrer"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                      className="rounded-circle"
                    />
                  ) : (
                    <Avatar
                      variant="circle"
                      sx={{
                        width: "175px",
                        height: "175px",
                        fontSize: "45px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      {name.split("")[0]}
                    </Avatar>
                  )}
                  <Box className="w-100 px-3">
                    <Typography
                      textAlign={"center"}
                      variant="h5"
                      className="fw-bold text-truncate overflow-hidden text-capitalize w-100"
                      gutterBottom
                    >
                      {localStorage.getItem("name")}
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      variant="body2"
                      color="text.secondary"
                      className="text-truncate overflow-hidden fw-bold w-100"
                      title={localStorage.getItem("email")}
                    >
                      {localStorage.getItem("email")}
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} sx={{ width: "50%" }}>
                    <LinearProgress
                      variant="buffer"
                      value={Number(percentage)}
                    />
                    <Typography
                      variant="body2"
                      color={"text.secondary"}
                      className="fw-bold py-2"
                    >
                      {percentage}% Completed
                    </Typography>
                  </Box>
                  <Button
                    onClick={handleBackP}
                    style={{ textDecoration: "underline" }}
                    variant="text"
                    className=" fw-bold text-capitalize"
                  >
                    Edit Profile
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              gap={2}
              maxWidth={"xxl"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={isSmallScreen ? 12 : 9}
                className="rounded-3 bg-white px-3 py-3"
              >
                <Typography
                  variant="body1"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  className="fw-bold"
                  gutterBottom
                >
                  <Typography variant="h5" className="fw-bold">
                    Recent Applications
                  </Typography>
                  {AppliedData.length > 0 ? (
                    <Button
                      variant="text"
                      className="fw-bold"
                      onClick={() => nav("/appliedJobs")}
                      sx={{
                        textDecoration: "underline",
                        textTransform: "unset",
                      }}
                    >
                      See all
                    </Button>
                  ) : (
                    <></>
                  )}
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={2}
                  flexWrap={"wrap"}
                  py={1.5}
                >
                  {loadingApplied ? (
                    // Show shimmer effect while loading data
                    <Box className="col-12">
                      <Box className="row row-cols-xl-3 mb-1">
                        {[...Array(3)].map((_, index) => (
                          <Box className="" key={index}>
                            <Shimmercard />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : AppliedData.length > 0 ? (
                    // Display AppliedCardUI if there is data available
                    <Box className="col-12">
                      <Box
                        className="row row-cols-xl-3"
                        style={{ transition: "opacity 1s" }}
                      >
                        {AppliedData.map((value, index) => (
                          <Box
                            className="mb-3"
                            key={index}
                            style={{
                              opacity: value.is_deleted === 1 ? 0.6 : 1,
                            }}
                          >
                            <AppliedCardUI
                              title={
                                <>
                                  {value.title}
                                  {value.is_deleted === 1 && (
                                    <Chip
                                      label="This Job is no longer available"
                                      color="error"
                                      style={{
                                        marginLeft: "20px",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                              name={value.name}
                              image={value.logo}
                              jobid={value.joborder_id}
                              date={value.applied_date}
                              ApplyStatus={value.isEmployerApplied}
                              is_deleted={value.is_deleted}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    // Show message for no applications if there is no data
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box textAlign={"center"}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          textAlign={"center"}
                        >
                          <img
                            className="img-fluid"
                            width={90}
                            src="./applicationEmoji.svg"
                            alt="Simple job application process with Jobeze"
                            title="Easy Job Application Process"
                          />
                        </Typography>
                        <Typography
                          variant="h5"
                          className="fw-bold"
                          gutterBottom
                        >
                          No applications
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body1"
                          color={"text.secondary"}
                        >
                          <Typography className="fw-bold">
                            Apply Now to see New Jobs
                          </Typography>
                          {/* <Button variant="outlined" className="basic-1-button my-3 fw-bold" sx={{ textTransform: "unset" }} onClick={() => nav("/dashboard")} >Search New Jobs</Button> */}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 2.8}>
                {subscriber === 0 && (
                  <Box pt={1.8} mb={2} px={2} className="rounded-3 bg-white">
                    <Box
                      variant="body1"
                      className="fw-bold"
                      display={"flex"}
                      gap={2}
                    >
                      <Typography variant="body1" gutterBottom>
                        <img src="./NotifyIcon.svg" alt="Notify" />
                      </Typography>
                      <Typography variant="body2">
                        <Typography
                          variant="body1"
                          className="fw-bold"
                          gutterBottom
                        >
                          Get relevant jobs
                        </Typography>
                        <Typography
                          variant="body2"
                          className="fw-semibold"
                          color={"text.secondary"}
                        >
                          Subscribe to get relevant jobs in your inbox
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{ mt: 2 }}
                      pb={2}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Button
                        variant="text"
                        className="fw-bold"
                        onClick={handleSubscribe}
                      >
                        Subscribe
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Box
                    py={1.5}
                    px={2}
                    maxWidth={"sm"}
                    className="rounded-2 bg-white"
                  >
                    <Box
                      variant="body1"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      gap={2}
                      className="fw-bold"
                    >
                      <Typography variant="body2">
                        <Typography
                          variant="h5"
                          className="fw-bold"
                          gutterBottom
                        >
                          Skills
                        </Typography>
                        {skills.length > 0 ? (
                          <></>
                        ) : (
                          <Typography
                            variant="body2"
                            color={"text.secondary"}
                            gutterBottom
                          >
                            Add at Least 5 skills to get job recommendations
                          </Typography>
                        )}
                      </Typography>
                      <Typography variant="body2" color={"text.secondary"}>
                        <IconButton aria-label="edit" onClick={handledrawer}>
                          <EditIcon
                            sx={{ cursor: "pointer" }}
                            color="text.secondary"
                          />
                        </IconButton>
                      </Typography>
                      {onclickdrawer ? (
                        <Recommendedrawer
                          setopen={setonclickdrawer}
                          setClose={handleCloseDrawer}
                          updateSkilledDrawer={updateSkilledDrawer}
                          setUpdateSkilledDrawer={setUpdateSkilledDrawer}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1.5}>
                      {skills.map((value, index) => (
                        <Chip
                          label={value.label}
                          key={index}
                          className="fz fw-bold"
                          sx={{
                            px: 1.5,
                            py: 0.5,
                            textTransform: "capitalize",
                            letterSpacing: 1.4,
                          }}
                          variant="filled"
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <FooterZ />
    </>
  );
}
