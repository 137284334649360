import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import LocationDropdown from './locationDropdown';
//import './searchBar.css';
import { set } from 'react-ga';


function SearchBar(props) {
    const [searchValue, setSearchValue] = useState('');
    const [showError, setShowError] = useState('');
    //const [designations, setDesignations] = useState([]);
    const [newlocations, setNewLocations] = useState([]);
    const [city, setCity] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const styleSearchIcon = {
        color: 'rgba(0, 0, 0, 0.6)',
    }
    const navigate = useNavigate();
    // const [skill, setSkill] = useState('');
    // const [location, setLocation] = useState('');

    const handleChange = (e) => {
        props.setSkill(e.target.value);
        setSearchValue(e.target.value)
    }

//     useEffect(() => {
//         async function fetchDesignations() {
//             try {
//                 const response = axios.get(`job-by-category`).then((response) => {
//                     // console.log(response,"vavajvs");
//                     const designationName = response.data.message.map((designationName) => designationName.value);
//                 setDesignations(designationName);
//                 console.log(designationName);
//                   });
//             } catch (error) {
//                 console.log(error);
//             }
//             }
// fetchDesignations();
//     }, []);
    
    const handleLocation = (e) => {
        props.setLocation(e.target.value);
        const input = e.target.value;
        setCity(input);
        //const suggestions = getSuggestions(input);
        setShowDropdown(input.trim() !== '');
        if (input.trim() === '') {
            setSelectedSuggestionIndex(-1);
        }
    }
    
    // const getSuggestions = (input) => {
    //     return newlocations.filter((location) => location.toLowerCase().includes(input.toLowerCase()));
    // };
    

    useEffect(() => {
        //console.log(locations);
        async function fetchLocations() {
            try {
                const response = await axios.post ("state-city/list",
                    {
                        isoCode: 'US',
                        cityName: city ,
                    }
                );
                const cityNames = response.data.message.map((cityName) => cityName.city.split("(")[0]);
            setNewLocations(cityNames);
            } catch (error) {
                console.log(error);
            }
        }
        fetchLocations();
    }, [city]);
    const handleLocationSelect = (selectedLocation) => {
        props.setLocation(selectedLocation);
        setNewLocations([]); 
        setShowDropdown(false);
    };
    const cleanInput = (input) => {
        return input.trim().replace(/\s+/g, ' ');
    };
    const handleSearch = (params) => {
        // const paramss = new URLSearchParams();
        // const cleanedSkill = cleanInput(props.skill);
        // const cleanedLocation = cleanInput(props.location);
        // const searchParamsString = paramss.toString();
        if (searchValue.trim() === '') {
            setShowError('Please enter a skill/designation/company to search');
            setTimeout(() => {
                setShowError('');
            }, 4000);
            return;
        }
        if (props.skill) {
            // paramss.append('search', cleanedSkill);
            // paramss.append('location', cleanedLocation ? cleanedLocation : 'World Wide');
            navigate(`/jobsearch/allsearchdata?search=${props.skill}&location=${props.location ? props.location : 'World Wide'}`);
            setShowError('');
        }
    }
    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            handleSearch(searchParams);
        }
    }


    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
          setSelectedSuggestionIndex((prevIndex) =>
            Math.min(prevIndex + 1, newlocations.length - 1)
          );
        } else if (e.key === 'ArrowUp') {
          setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
          handleLocationSelect(newlocations[selectedSuggestionIndex]);
        }
      };

    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchParams({ skill: props.skill, location: props.location, description: props.skill });
        handleSearch(searchParams);
    };
    return (
        <div className='row m-0 align-items-center justify-content-center fx'>
            <form onSubmit={handleSubmit} className='bg-white mx-lg-4 position-relative rounded-pill boxshadowsearch ' style={{ padding: '15px 24px'}} >
                <div className="d-flex align-items-center justify-content-between gap-lg-3 position-relative"  >
                    <div className="d-flex align-items-center">
                        <SearchIcon
                            fontSize="small"
                        />
                        <input
                            type="text"
                            className="fw-bold border-0 bg-transparent"
                            placeholder="Skills/designation/company"
                            value={props.skill}
                            onChange={handleChange}
                            onKeyDown={onEnterPress}
                            style={{ width: "200px" }}
                            autoFocus
                        />
                    </div>
                    <div className="border-2 border-end mobilocation" style={{ borderColor: "gray", height: "3ch" }} ></div>
                    <div className="d-flex align-items-center  ">
                        <LocationOnOutlinedIcon
                            fontSize="small"
                            className='mobilocation'
                        />
                        <input
                            type="text"
                            className="fw-bold border-0 bg-transparent px-2 w-100 mobilocation"
                            placeholder="Location"
                            onChange={handleLocation}
                            onKeyDown={handleKeyDown}
                            value={props.location}
                        />
                        {props.location && showDropdown && newlocations.length > 0 && (
           
           <LocationDropdown  handleLocationSelect={handleLocationSelect} newlocations={newlocations} selectedSuggestionIndex={selectedSuggestionIndex}/>
                            
                        )}
                        <div className='d-none d-lg-block d-md-block d-sm-block'>
                            <Button className='rounded-pill fw-bold py-2 px-3' size='small' onClick={handleSearch} variant="contained">Search</Button>
                        </div>
                        <div className='d-block d-lg-none d-md-none d-sm-none bg-primary rounded-circle p-2 ms-2 '>
                            <SearchIcon className='' size='small' sx={{color : "white"}} onClick={handleSearch} />
                        </div>
                    </div>
                    {showError && <p className="error-message2 px-4 py-2 shadow-lg">{showError}</p>}
                </div>
            </form>
        </div>


    )
}

export default SearchBar;
