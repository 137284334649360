import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import LocationDropdown from '../components/locationDropdown';
import '../components/searchBar.css';
import { Helmet } from 'react-helmet';
//import './mobilesearch.css';
function MobileSearch() {
    const token = document.cookie.split("_secure_ARJ_=")[1];
    const nav = useNavigate();
    const handleBack = () => {
        if (token) {
            nav('/dashboard');
        }
        else {
            nav('/');
        }
    }


    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('');
    const [location, setLocation] = useState('');
    const [searchError, setSearchError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    //const [locationOptions, setLocationOptions] = useState([]);
    const [city, setCity] = useState('');
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    const [showDropdown, setShowDropdown] = useState(false);
    const [newLocations, setNewLocations] = useState([]);

    const handleLocationChange = async (e) => {
        const inputValue = e.target.value;
        setLocation(inputValue);
        //setShowDropdown(true);
        setCity(inputValue);
        //const suggestions = getSuggestions(input);
        setShowDropdown(inputValue.trim() !== '');
        if (inputValue.trim() === '') {
            setSelectedSuggestionIndex(-1);
        }
    };
    useEffect(() => {
        //console.log(locations);
        async function fetchLocations() {
            try {
                const response = await axios.post("state-city/list",
                    {
                        isoCode: 'US',
                        cityName: city,
                    }
                );
                const cityNames = response.data.message.map((cityName) => cityName.city.split("(")[0]);
                setNewLocations(cityNames);
            } catch (error) {
                console.log(error);
            }
        }
        fetchLocations();
    }, [city]);
    const handleLocationSelect = (selectedLocation) => {
        setLocation(selectedLocation);
        setNewLocations([]);
        setShowDropdown(false);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, newLocations.length - 1)
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
            handleLocationSelect(newLocations[selectedSuggestionIndex]);
        }
    };

    const handleMobileSearch = (e) => {
        e.preventDefault();
        setSearchError(search === '');

        const params = new URLSearchParams();

        if (search) {
            params.append("search", search ? search : "");
            params.append("location", location ? location : "World Wide");

            // Convert URLSearchParams to a string
            const queryString = params.toString();

            // Navigate with the query string
            nav(`/jobsearch/allsearchdata${queryString ? "?" + queryString : ""}`, {
                state: { searchParams: queryString }, // Use a plain string or object
            });
        }

        if (!recentSearches.includes(search)) {
            const updatedRecentSearches = [search, ...recentSearches];
            localStorage.setItem('recentSearches', JSON.stringify(updatedRecentSearches.slice(0, 3)));
            setRecentSearches(updatedRecentSearches.slice(0, 3));
        }
    }


    const [recentSearches, setRecentSearches] = useState([]);

    useEffect(() => {
        if (searchParams.get("search")) {
            setSearch(searchParams.get("search"));
            setLocation(searchParams.get('location'))
        }
    }, [searchParams]);


    useEffect(() => {
        const savedSearches = JSON.parse(localStorage.getItem('recentSearches') || "[]");
        setRecentSearches(savedSearches);
    }, []);

    const handleRecentSearchClick = (searchItem) => {
        const paramss = new URLSearchParams();

        paramss.append("search", searchItem);
        // If you also want to include location, you can do so here, 
        // for now, I'll use the current value of location
        paramss.append("location", location ? location : "World Wide");

        nav(`/jobsearch/allsearchdata${paramss ? "?" + paramss : ""}`, {
            state: searchParams,
        });
    }


    // const handleLocationSelection = (selectedLocation) => {
    //     setLocation(selectedLocation);
    //     // Clear location options after selection
    //     setLocationOptions([]);
    // };


    return (
        <>
            <Helmet>
                <title> AI Job Assistant – Mobile Job Search Automation</title>
                <meta name="title" content='AI Job Assistant – Mobile Job Search Automation' />
                <meta name="description" content="Find jobs on the go with Jobeze's AI-powered job search. Use our mobile job search platform for quick job listings, applications, and seamless job hunting online." />
            </Helmet>
            <div className='row fx'>
                <div className='d-flex p-3' style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)" }}>
                    <div className='px-3' onClick={handleBack} style={{ cursor: "pointer" }}  >
                        <KeyboardBackspaceIcon sx={{ color: 'rgba(66, 66, 66, 1)' }} />
                    </div>
                    <div className='h5 fw-bold m-0' style={{ color: "rgba(66, 66, 66, 1)", letterSpacing: "0.15ch" }}>
                        Search Jobs
                    </div>
                </div>
                <form onSubmit={handleMobileSearch} >
                    <div className='d-flex flex-column justify-content-between' style={{ height: "85vh" }}  >
                        <div className='py-4'>
                            <div className='px-4 pb-3'>
                                <TextField
                                    id="outlined-basic"
                                    label="Title"
                                    placeholder='Enter skills/designation/company'
                                    variant="outlined"
                                    autoFocus
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    fullWidth
                                    style={{ marginBottom: "25px" }}
                                    error={searchError}
                                    helperText={searchError && "Please Search Something"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"> <SearchIcon /> </InputAdornment>,
                                    }}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Location"
                                    placeholder='Search Location'
                                    variant="outlined"
                                    fullWidth
                                    value={location}
                                    onChange={handleLocationChange}
                                    onKeyDown={handleKeyDown}
                                    error={locationError}
                                    helperText={locationError && "Location is required"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"> <SearchIcon /> </InputAdornment>,
                                    }}
                                />
                                {showDropdown && newLocations.length > 0 && (

                                    <LocationDropdown handleLocationSelect={handleLocationSelect} newlocations={newLocations} selectedSuggestionIndex={selectedSuggestionIndex} />
                                )}
                            </div>
                            {
                                recentSearches.length > 0 && (
                                    <div className='px-4 py-2 '>
                                        <span className='text-muted fw-bolder  ' style={{ fontSize: "16px", letterSpacing: '1.4px ' }} >Recent Searches</span>
                                        <ul className="nav flex-column ">
                                            {recentSearches.map((searchItem, index) => (
                                                <li key={index} className="nav-item " style={{ fontSize: "14px" }} >
                                                    <Link
                                                        className="nav-link ps-0 fw-bold text-decoration-underline text-capitalize  "
                                                        style={{ letterSpacing: '2px ' }}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // To prevent default behavior of anchor tags
                                                            handleRecentSearchClick(searchItem);
                                                        }}
                                                    >
                                                        {searchItem}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                        <div className='px-5'>
                            <Button fullWidth variant='contained' type='submit' disabled={search === ""} className='rounded-1 fw-bold' >Search</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default MobileSearch;