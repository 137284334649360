import React, { useEffect, useState } from "react";
// import Header from "./header";
import Status from "./Status";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Backdrops from "./Backdrop";
// import Errorpage from "./Error404";
import FooterZ from "./FooterZ";
import Button from '@mui/material/Button';
import Homeheader from "./homeheader";
import { Box, Chip, Pagination, Typography } from '@mui/material';

// import { useLocation } from 'react-router-dom';
// import { useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Helmet } from "react-helmet";
// import { DoorFront } from "@mui/icons-material";
// import Fade from '@mui/material/Fade';

const Applicationsection = () => {
  const nav = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  // const [errorData, setErrorData] = useState(false);
  // const [skill, setSkill] = useState(searchParams.get("search") ? searchParams.get("search") : "");
  // const [location, setLocation] = useState(searchParams.get("location") ? searchParams.get("location") : "World Wide");
  const [isshow, setisShow] = useState(false);

  // const isSmallScreen = useMediaQuery('(max-width: 625px)');
  // const isMediumScreen = useMediaQuery('(max-width: 1200px)');
  const [totalJobs, setTotalJobs] = useState();
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const containerRef = React.useRef(null);


  useEffect(() => {
    setTimeout(() => {
      setisShow(true);
    }, 1500);
  }, [])

  useEffect(() => {
    axios
      .get(`user/applied/all-job/page-no/${page}?shortlisted=false`, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response.data.message.data.length > 0) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setIsData(true);
          setTotalJobs(response.data.message.totalJob);
          setTotalPages(response.data.message.totalPages);
        }
        setData(response.data.message.data);
        setTotalJobs(response.data.message.totalJob);
        setTotalPages(response.data.message.totalPages);
        setIsOpen(false);
      })
      .catch((err) => {
        setIsOpen(false);
      });
  }, [page]);

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setPage(value);
  };



  return (
    <>
      <Helmet>
        <title>Applied Jobs – Track Your Job Applications</title>
        <meta name="title" content='Applied Jobs – Track Your Job Applications' />
        <meta name="description" content="View and manage all your applied jobs on Jobeze. Keep track of your job applications and stay organized in your AI-powered job search journey." />
      </Helmet>
      <Homeheader
      />
      {isOpen && <Backdrops />}
      <div className="ApplicationPage fx">
        <Box p={2} sx={{ backgroundColor: "white" }} className="mb-3" >
          <Box className="container" display={'flex'} alignItems={'center'} >
            <div onClick={() => nav("/dashboard")} style={{ cursor: "pointer" }}> <ArrowBackIosIcon fontSize="14px" /> </div>
            <h1>   <div onClick={() => nav("/dashboard")} style={{ cursor: "pointer" }} className="fw-bold fs-5 px-2" > Applications {data.length > 0 && `(${totalJobs})`}  </div></h1>
          </Box>
        </Box>

        <div ref={containerRef} className="jbscroll">
          <div className="container pb-4 ">
            {isData ? (
              <div className="fx">
                {data.map((value, index) => {
                  return (
                    <div className="my-2" key={index} style={{ transition: 'opacity 1s', opacity: value.is_deleted === 1 ? 0.6 : 1 }}>
                      <Status
                        title={value.title}
                        date_modified={value.date_modified}
                        name={value.name}
                        jobid={value.joborder_id}
                        date={value.applied_date}
                        isEmployerApplied={value.isEmployerApplied}
                        cj_date_modified={value.cj_date_modified}
                        is_deleted={value.is_deleted}
                      />
                    </div>
                  );
                })}
                <div className="py-3 d-flex justify-content-center">
                  <Pagination
                    className='justify-content-center align-items-center m-auto'
                    count={totalPages}
                    page={page}
                    size='small'
                    color="primary"
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            ) : isshow && (
              <div className="d-flex justify-content-center align-items-center fx" style={{ height: "75vh" }}>
                <div className="text-center">
                  <div className="mb-4">
                    <img className="img-fluid" src="./applicationEmoji.svg" alt="Simple job application process with Jobeze" title="Easy Job Application Process" />
                  </div>
                  <div className="display-6">
                    <p>No applications</p>
                  </div>
                  <div className="h4 lead">
                    <p className="pb-4">Start applying for jobs to see your applications.</p>
                    <Button variant="contained" className="basic-1-button px-5 fw-bold" onClick={() => nav("/dashboard")} >explore jobs</Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <FooterZ />
      </div>
    </>
  );
};

export default Applicationsection;
