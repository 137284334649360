import "./HeadersearchBar.css";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import LocationDropdown from "./locationDropdown";
//import './locationDropdown.css';
function HeadersearchBar() {
  const navigate = useNavigate();
  const [skill, setSkill] = useState("");
  const [location, setLocation] = useState("");
  const [newlocations, setNewLocations] = useState([]);
  const [city, setCity] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const loc = useLocation();
  const hideSearchBar =
    loc.pathname === "/forgetpassword" || loc.pathname === "/";

  const handleChange = (e) => {
    setSkill(e.target.value);
  };

  const handleLocation = (e) => {
    setLocation(e.target.value);
    const input = e.target.value;
    setCity(input);
    //const suggestions = getSuggestions(input);
    setShowDropdown(input.trim() !== '');
    if (input.trim() === '') {
      setSelectedSuggestionIndex(-1);
    }
  };

  const cleanInput = (input) => {
    return input.trim().replace(/\s+/g, " ");
  };

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const cleanedSkill = cleanInput(skill);
  //   const cleanedLocation = cleanInput(location);
  //   const paramss = new URLSearchParams();
  //   if (skill) {
  //     paramss.append("search", cleanedSkill);
  //     paramss.append(
  //       "location",
  //       cleanedLocation ? cleanedLocation : "World Wide"
  //     );
  //     navigate(`/jobsearch/allsearchdata${paramss ? "?" + paramss : ""}`, {
  //       state: searchParams,
  //     });
  //   }
  // };
  //   const getSuggestions = (input) => {
  //     return newlocations.filter((location) => location.toLowerCase().includes(input.toLowerCase()));
  // };

  useEffect(() => {
    //   console.log(city);
    //   if (!city) {
    //     // If city is empty, do not make the API call
    //     return;
    // }

    //console.log(locations);
    async function fetchLocations() {
      try {
        const response = await axios.post("state-city/list",
          {
            isoCode: 'US',
            cityName: city,
          }
        );
        const cityNames = response.data.message.map((cityName) => cityName.city.split("(")[0]);
        setNewLocations(cityNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchLocations();
  }, [city]);
  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    setNewLocations([]);
    setShowDropdown(false);
  };

  const handleKeyDown = (e) => {

    if (e.key === 'ArrowDown') {
      setSelectedSuggestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, newlocations.length - 1)
      );
    } else if (e.key === 'ArrowUp') {
      setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
      handleLocationSelect(newlocations[selectedSuggestionIndex]);
    }
  };



  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = (e) => {
    e.preventDefault();

    const cleanedSkill = cleanInput(skill);
    const cleanedLocation = cleanInput(location);

    if (window.location.pathname.includes(`jobsearch/allsearchdata`)) {
      setSearchParams({ search: cleanedSkill, location: cleanedLocation ? cleanedLocation : "World Wide" });
    }
    else {
      navigate(`/jobsearch/allsearchdata?search=${cleanedSkill}&location=${cleanedLocation ? cleanedLocation : "World Wide"}`);
    }
  };

  useEffect(() => {
    if (searchParams.get("search") || searchParams.get("location")) {
      setSkill(searchParams.get("search").trim());
      setLocation(searchParams.get("location").trim());
    }
    // const cleanedSkill = cleanInput(skill);
    // const cleanedLocation = cleanInput(location);
    // setSearchParams({ search: cleanedSkill, location: cleanedLocation ? cleanedLocation : "World Wide" });
  }, [searchParams, setSearchParams]);

  return (
    <div className="fx">
      <form>
        {hideSearchBar ? null : (
          <div
            className="mx-lg-4 position-relative border border-2 rounded-pill"
            style={{ padding: "4px 4px 4px 16px", backgroundColor: "#F3F8FB" }}
          >
            <div className="d-flex align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center">
                <SearchIcon fontSize="small" />
                <input
                  type="text"
                  className="fw-bold border-0 bg-transparent"
                  placeholder="Enter skills/designation/company"
                  value={skill}
                  style={{ width: "240px" }}
                  onChange={handleChange}
                />
              </div>
              <div
                className="border-2 border-end"
                style={{ borderColor: "gray", height: "3ch" }}
              ></div>
              <div className="d-flex align-items-center">
                <LocationOnOutlinedIcon fontSize="small" />
                <input
                  type="text"
                  className="fw-bold border-0 bg-transparent px-2"
                  placeholder="Location"
                  onChange={handleLocation}
                  onKeyDown={handleKeyDown}
                  value={location}
                />
                {location && showDropdown && newlocations.length > 0 && (
                  <LocationDropdown handleLocationSelect={handleLocationSelect} newlocations={newlocations} selectedSuggestionIndex={selectedSuggestionIndex} />

                  //            <div className="dropdown-location">
                  //            <ul className="dropdown-menu-location" style={{ maxHeight: '200px',overflowY: 'auto', display: showDropdown ? 'block' : 'none' }}>
                  //            {newlocations.slice(0, 10).map((location, index) => (
                  // <li key={location} className="dropdown-item-location" onClick={() => handleLocationSelect(location)} style={{ backgroundColor: index === selectedSuggestionIndex ? 'lightblue' : 'white' }}>{location}</li>
                  // ))}
                  //            </ul>
                  //        </div>
                )}
                <button
                  className="rounded-circle border-0"
                  style={{
                    backgroundColor: "#006CB7",
                    width: "32px",
                    height: "32px",
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon
                    className=""
                    fontSize="small"
                    sx={{ color: "#FFFFFF" }}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default HeadersearchBar;
