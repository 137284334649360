import React from 'react';
import Homeheader from '../components/homeheader';
import './About.css';
import FooterN from '../components/FooterN';
import FooterZ from '../components/FooterZ';
import { Helmet } from 'react-helmet';

const Aboutus = () => {
  const token = document.cookie.split("_secure_ARJ_=")[1];
  return (
    <><Helmet>
      <title>Professional Job Seeker Platform | Trusted Job Portal | Jobeze</title>
      <meta name="title" content='Professional Job Seeker Platform | Trusted Job Portal | Jobeze' />
      <meta name="description" content="Welcome to Jobeze! Learn how we aim to transform the job search experience for seekers and employers alike with our advanced online job search engine." />
    </Helmet>
      <div className='fx'>
        <div className='sticky-top bg-white'>
          <Homeheader />
        </div>
        <main>
          <section className='aboutMain1'>
            <div className='container text-center py-5'>
              <div className='p-lg-5'>
                <h1>
                  <div className='AboutSection1 p-lg-3 py-2 px-3 display-5 fw-bolder'>
                    Empowering job seekers by simplifying the application process, making it more efficient and inclusive.
                  </div></h1>
                <div className='fw-bold AboutSection1-1 AboutSection1-6  '>
                  We strive to provide a platform that connects talented individuals with their dream jobs, while removing barriers and streamlining the hiring process for employers.
                </div>
              </div>
              <div className='w-100 py-lg-0 py-2 '>
                <img className="img-fluid w-100" src="/aboutL.svg" alt='Learn more about Jobeze job search platform' title='About Jobeze - AI-Powered Job Platform'></img>
              </div>
            </div>
          </section>
          <section className='bg-white'>
            <div className='container d-flex flex-column gap-4 py-5'>
              <div className='col-lg-12 fw-bold'>Our Values</div>
              <div className='col-lg-6 display-6 fw-bold '>
                We abide by these values and incorporate it in our operations
              </div>
              <div className='col-lg-6 AboutSection2'>By adhering to these vision, mission, and values, our app aims to create a positive impact by making the job application process more accessible, efficient, and user-centric.</div>
              <div className='row m-0 fw-bold py-lg-5 py-3'>
                <div className='col-lg-4  d-flex flex-column gap-3 gap-lg-4 px-3 py-lg-0 pb-5 '>
                  <div>
                    <img className="img-fluid" src="/groups_2.png" alt='Group job recommendations on Jobeze' title='Job Recommendations for Group Applications' />
                  </div>
                  <div className='h3 mb-0 fw-bold'>Collaboration</div>
                  <div>
                    We foster collaboration between job seekers and employers, facilitating meaningful connections. Our platform encourages open communication and transparency, allowing job seekers to showcase their unique talents and enabling employers to make informed hiring decisions.
                  </div>
                </div>
                <div className='col-lg-4 d-flex flex-column gap-4 px-3 py-lg-0 pb-5 '>
                  <div>
                    <img className="img-fluid" src="/groups_2.png" alt='Group job recommendations on Jobeze' title='Job Recommendations for Group Applications' />
                  </div>
                  <div className='h3 mb-0 fw-bold'>Efficiency</div>
                  <div>
                    We are committed to saving both job seekers' and employers' time and effort. Our app automates certain aspects of the application process, allowing applicants to focus on showcasing their skills and qualifications, while employers can efficiently review and assess candidates.
                  </div>
                </div>
                <div className='col-lg-4 d-flex flex-column gap-4 px-3 py-lg-0 '>
                  <div>
                    <img className="img-fluid" src="/groups_2.png" alt='Group job recommendations on Jobeze' title='Job Recommendations for Group Applications' />
                  </div>
                  <div className='h3 mb-0 fw-bold'>Simplicity</div>
                  <div>
                    We value simplicity and aim to create an intuitive and straightforward user experience. We understand that job hunting can be overwhelming, and our app strives to streamline the application process, making it as simple as possible.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='aboutMain1'>
            <div className='container text-center py-5'>
              <div className='py-2 fw-bold'>Our Vision</div>
              <div className='py-2 AboutSection1-1 text-dark AboutSection3'>
                To revolutionize the job application process by providing a seamless and user-friendly platform that allows individuals to apply for jobs effortlessly using their resumes, eliminating the need for complex logins and ensuring equal access to employment opportunities.
              </div>
            </div>
          </section>
        </main>
        {token ? (
          <FooterZ />
        )
          :
          <FooterN />}
      </div></>
  )
}

export default Aboutus