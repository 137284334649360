import React, { useEffect, useState } from "react";
import "./Status.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment.js";
import axios from "axios";
import { toast } from "react-toastify";
import JobView from "./JobView";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AppliedCardUI = (props) => {
  const [isopen, setIsOpen] = useState(false);
  const [isRevoke, setRevoke] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [props]);

  const nav = useNavigate();
  const handleRevoke = () => {
    axios
      .post(`user/applied-job/revoke`, {
        token: document.cookie,
        job_id: props.jobid,
      })
      .then((response) => {
        toast.success("Job Revoked Successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        setRevoke(false);
        window.location.href = "/appliedJobs";
      });
  };
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");
  // console.log(props, "props");

  const handleClick = () => {
    if (props.is_deleted === 0) {
      if (isSmallScreen) {
        window.open(`/jobDetail/${props.jobid}`, "_blank");
      } else {
        nav(`/jobDetail/${props.jobid}`);
      }
    }
  };
  

  return (
    <>
      {/* {isopen && <JobView jobID={props.jobid} setIsOpen={setIsOpen} />} */}
      <div className="border rounded p-3 d-flex flex-column gap-3 hoverstates" style={{ cursor: 'pointer' }} onClick={handleClick}>
        {/* <Typography variant="title" className="fw-bold text-truncate cursor-pointer" title={props.title} >
          <img src={props.image} alt="" className="img-fluid" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
        </Typography> */}
        <Typography variant="title" className="fw-bold text-truncate cursor-pointer" sx={{textTransform: 'capitalize'}} title={props.title}>
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={"bold"}
        >
          {props.name}
        </Typography>
        <Typography variant="body2" color={"text.secondary"} fontWeight={"bold"}>
          Applied on {moment(props.date).format("MM/DD/YYYY")}
        </Typography>
      </div>
    </>
  );
};

export default AppliedCardUI;
