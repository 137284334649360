import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, FormControl, OutlinedInput } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GenerateCvShimmer from "./generateCvShimmer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const GenerateCLPopup = (props) => {

    const jobData = props.data;


    const handleKeyDown = (e) => {
        // Prevent copying (Ctrl+C or Command+C)
        if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === "c") {
            e.preventDefault();
            toast.warning("You can't copy");
        }
    };
    const handleContextMenu = (e) => {
        // Prevent right-click context menu
        e.preventDefault();
    };
    // const isSmallScreen = useMediaQuery("(max-width: 625px)");
    // console.log(props,"clprops");

    const reactQuillRef = useRef();
    const [currentLength, setCurrentCharCount] = useState(0);
    const [exceededLimit, setExceededLimit] = useState(false);

    const checkCharacterCount = (e) => {
        const unprivilegedEditor = reactQuillRef.current.getEditor();
        const length = unprivilegedEditor.getLength();
        setCurrentCharCount(length);
        if (length > 3999 && e.key !== 'Backspace') {
            e.preventDefault();
            setExceededLimit(true);
        } else {
            setExceededLimit(false);
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData("text/plain");
        const pastedLength = pastedData.length;
        const totalLength = currentLength + pastedLength;

        if (totalLength > 3999) {
            // e.preventDefault(); // Prevent pasting data
            setExceededLimit(true);
            // toast.error("Exceeded character limit");
        } else {
            setExceededLimit(false);
        }
    };

    const handleAdditionInfoChangeCL = (e) => {
        const { value } = e.target;
        if (value.length > 500) {
            props.setExceededLimitAdditionalInfo(true);
        } else {
            props.setExceededLimitAdditionalInfo(false);
            props.handleAdditionInfoChange(e);
        }
    };

    // const shouldDisableButton = () => {
    //     if (jobData.questionnaire_required === 1 && jobData.cover_letter_required === 0) {
    //         return false; // Button active hoga
    //     } 
    //     else if (jobData.questionnaire_required === 1 && jobData.cover_letter_required === 1) {
    //         // HTML content aur additional information ki validation check karein
    //         return (
    //             (props.HtmlContent === null || props.loading) ||
    //             ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") && props.additionInfoData === "") ||
    //             exceededLimit ||
    //             currentLength > 3999 ||
    //             props.exceededLimitAdditionalInfo
    //         );
    //     } 
    //     else if (jobData.questionnaire_required === 0 && jobData.cover_letter_required === 0) {
    //         return (
    //             (props.HtmlContent === null || props.loading) ||
    //             ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") && props.additionInfoData === "") ||
    //             exceededLimit ||
    //             currentLength > 3999 ||
    //             props.exceededLimitAdditionalInfo
    //         ); // Button disabled hoga
    //     }
    //     else if (jobData.questionnaire_required === 0 && jobData.cover_letter_required === 1) {
    //         return (
    //             (props.HtmlContent === null || props.loading) ||
    //             ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") && props.additionInfoData === "") ||
    //             exceededLimit ||
    //             currentLength > 3999 ||
    //             props.exceededLimitAdditionalInfo
    //         ); // Button disabled hoga
    //     }
    //     else {
    //         return true;
    //     }
    // };

    return (
        <div>
            <BootstrapDialog
                onClose={props.handleCloseCLPopup}
                aria-labelledby="customized-dialog-title"
                open={props.openCLPopup}
                fullWidth
                maxWidth="md"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "8px",
                        height: { xs: "auto", md: "90vh" },
                    },
                }}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    className="fw-bold px-4 text-truncate overflow-hidden w-75"
                    id="customized-dialog-title"
                >
                    Apply to {props.data.title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.handleCloseCLPopup}
                    sx={{
                        position: "absolute",
                        right: 7,
                        top: 7,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="px-lg-4">
                    <Typography gutterBottom>
                        <Typography gutterBottom >
                            <Typography variant="h6" className="fw-bold" gutterBottom>
                                Cover Letter{" "}
                            </Typography>
                            <Typography variant="body2" className="text-muted fw-bold w-75">
                                Personalize your application by attaching a cover
                                letter for the job.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography
                        gutterBottom
                        onKeyDown={handleKeyDown}
                        onContextMenu={handleContextMenu}
                        onPaste={handlePaste}
                        className="py-3 position-relative"
                    >
                        {props.loading ? (
                            <GenerateCvShimmer />
                        ) : (
                            <ReactQuill
                                value={props.HtmlContent}
                                placeholder="Write here..."
                                onChange={props.handleCVHtmlChange}
                                onKeyDown={checkCharacterCount}
                                onKeyPress={checkCharacterCount}
                                onFocus={checkCharacterCount}
                                onBlur={checkCharacterCount}
                                onContextMenu={handleContextMenu}
                                onChangeSelection={checkCharacterCount}
                                onPaste={handlePaste}
                                ref={reactQuillRef}
                                preserveWhitespace
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, false] }],
                                        ["bold", "italic", "underline"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                    ],
                                }}
                                theme="snow"
                                scrollingContainer={".MuiDialogContent-root"}
                            />
                        )}
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="py-1">
                            <Typography>
                                {
                                    exceededLimit &&
                                    <Typography className="fw-bold text-danger py-2" sx={{ fontSize: "13px" }}> Maximum 4000 characters allowed </Typography>
                                }
                            </Typography>
                            <Typography className="fw-bold text-end text-muted px-1" sx={{ fontSize: "10px" }}> max-characters : {currentLength} / 4000 </Typography>
                        </Box>
                    </Typography>
                    <Typography gutterBottom>
                        <Typography className="fw-bold" gutterBottom>
                            Additional info
                        </Typography>
                        <Typography>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                    multiline
                                    minRows={2.5}
                                    value={props.additionInfoData}
                                    placeholder="Add more info"
                                    className="w-100"
                                    name="additionInfo"
                                    id="additionInfo"
                                    // onInput={handleAdditionInfoChange}
                                    onChange={handleAdditionInfoChangeCL}
                                    type="text"
                                    aria-describedby="additionInfo-helper-text"
                                    inputProps={{
                                        "aria-label": "additionInfo",
                                        // maxLength: 200, // Set the maximum character limit here
                                    }}
                                />
                            </FormControl>
                            {props.exceededLimitAdditionalInfo && (
                                <Typography variant="subtitle2" color="error" className="fw-bold text-danger py-2" sx={{ fontSize: "13px" }}>
                                    Maximum 500 characters allowed
                                </Typography>
                            )}
                        </Typography>
                    </Typography>
                </DialogContent>
                <DialogActions className="px-4" sx={{ justifyContent: "space-between" }}>
                    <Button
                        onClick={props.handleBackCL}
                        variant="outlined"
                        sx={{ color: "#444a46" }}
                        className="text-muted text-capitalize fw-bold rounded-pill border-1 border-secondary px-4"
                    >
                        Back
                    </Button>
                    <Box>
                        <Button
                            onClick={props.handleCloseCLPopup}
                            variant="text"
                            className="text-muted text-capitalize fw-bold"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            className="rounded-pill text-capitalize px-3 fw-bold"
                            onClick={props.handleSubmit2}
                            disabled={(props.HtmlContent === null || props.loading) || ((props.HtmlContent === "<p><br></p>" || props.HtmlContent === "") )}
                        >
                            Apply
                        </Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default GenerateCLPopup;
