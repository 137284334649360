import React from "react";
import { useState } from "react";
//import './searchBar.css';
//import './locationDropdown.css';
const LocationDropdown = ({
  newlocations,
  handleLocationSelect,
  selectedSuggestionIndex,
}) => {
  return (
    <div className="dropdown-location bg-white" style={{ left: window.location.pathname === "/" ? "50%" : "53%" }}>
      <ul
        className=" list-unstyled jbscroll bg-white px-1 py-1 m-1 rounded-3"
        style={{ display: "block", maxHeight: "200px", overflowY: "scroll" }}
      >
        {newlocations.map((location, index) => (
          <li
            key={location}
            className="dropdown-item-location bg-white"
            onClick={() => handleLocationSelect(location)}
            // style={{
            //   backgroundColor:
            //     index === selectedSuggestionIndex ? "lightgrey" : "white",
            // }}
          >
            {location}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationDropdown;
