import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ShortListedStatus from './shortListedStatus';
import axios from "axios";
import SavedShimmer from './SavedShimmer';
import Recommendedempty from './Recommendedempty';
import { toast } from 'react-toastify';


const ShortList = ({ id }) => {
  const scrollContainerRef = useRef(null);
  const [countjobs, setcountjobs] = useState(0);
  const [checkjobid, setcheckjobid] = useState([]);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summit, setsummit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [save, setSave] = useState({});
  const [totalJobs, setTotalJobs] = useState();

  let currentPage = 1;
  let isLoading = false;
  let more = true;

  const fetchMoreData = async () => {
    if (!more || isLoading) {
      return;
    }

    // Set isLoading to true before making the request
    isLoading = true;
    setLoading(true);
    try {
      const response = await axios.get(
        `user/applied/all-job/page-no/${currentPage}?shortlisted=true`,
        {
          headers: { authorization: document.cookie },
        }
      );
      currentPage = currentPage + 1;

      const newData = response.data.message.data;
      // console.log(newData, "422222");
      setTotalJobs(response.data.message.totalJob);

      if (newData.length > 0) {
        // Append new data to the existing data
        setGetData((prevData) => [...prevData, ...newData]);
        const newSaveData = newData.reduce((acc, job) => {
          acc[job.joborder_id] = job.isSaved;
          return acc;
        }, {});
        setSave((prevSave) => ({ ...prevSave, ...newSaveData }));
        // setData(newData);
      } else {
        setHasMore(false);
        more = false;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Reset isLoading to false after the request is done
      isLoading = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const handleCheckall = async () => {
    // Filter jobs based on cover letter and questionnaire requirements
    const uncheckedJobIds = getData.reduce((acc, job) => {
      if (job.cover_letter_required !== 1 && job.questionnaire_required !== 1 && job.is_deleted !== 1) {
        acc.push(job.joborder_id);
      }
      return acc;
    }, []);


    // Select up to 7 jobs
    const selectedJobIds = uncheckedJobIds.slice(0, 7);

    // Update the checked job array and count
    setcheckjobid(selectedJobIds);
    setcountjobs(selectedJobIds.length);
  };

  const handleUnSave = async (id) => {
    setsummit(true);
    try {
      const response = await axios.post(`user/unsave-job`, {
        token: document.cookie,
        isRecommended: true,
        job_id: id,
      });
      toast.success("Job unsaved successfully", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      setGetData((prevData) => prevData.filter((e) => e.joborder_id !== id));
      if (getData.length === 0) {
        fetchMoreData();
      }
    }
    catch (error) {
      console.log(error);
    }
    setsummit(false);

    // let tempData = [...getData];
    // tempData = tempData.filter((e) => e.joborder_id !== id);
    // setData([...tempData]);

  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = container.scrollTop;
      const maxScrollHeight = container.scrollHeight - container.clientHeight;
      const threshold = 100;
      if (scrollPosition >= maxScrollHeight - threshold) {
        fetchMoreData();
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  function findObjectsById(ids, data) {
    const result = [];
    ids.forEach(id => {
      const found = data.find(item => item.joborder_id === id);
      if (found) {
        result.push({ job: found.joborder_id, original_date_modified: found.date_modified });
      }
    });
    return result;
  }

  const result = findObjectsById(checkjobid, getData);

  const handleBulkapply = async () => {
    try {
      // setcountjobs(0);
      if (checkjobid.length > 7) {
        toast.warning("You can only apply to a maximum of 7 jobs at a time", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        return;
      }

      const response = await axios.post(`user/bulk-applied-quick/job`, {
        token: document.cookie,
        job_ids: result,
        isShortlisted: 1,
      });
      toast.success("Job applied successfully", {
        hideProgressBar: true,
        closeOnClick: true,
      });

      // If the response is successful, call fetchMoreData immediately
      if (response.status === 200) {
        setcheckjobid([]);
        setcountjobs(0);
        if (!more || isLoading) {
          return;
        }

        // Set isLoading to true before making the request
        isLoading = true;
        setLoading(true);
        if (response.status === 200) {
          setLoading(false);
          setcheckjobid([]);
          setcountjobs(0);
          let tempData = [...getData];

          // Remove the applied jobs from the displayed data
          tempData = tempData.filter((job) => !checkjobid.includes(job.joborder_id));

          setGetData([...tempData]);
          setTotalJobs((prevTotalJobs) => prevTotalJobs - checkjobid.length);

          // If there are no more jobs to display, fetch more data
          if (tempData.length === 0) {
            fetchMoreData();
            setLoading(false);
          }
        }
      }
    } catch (err) {
      toast.error(err.response.data.error, {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };

  const handleSave = async (joborder_id) => {
    if (!document.cookie.split("_secure_ARJ_")[1]) {
      toast.warning("Please login to save", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    try {
      const currentSaveStatus = save[joborder_id] || false;

      const response = await axios.post(`user/${currentSaveStatus ? 'unsave' : 'save'}-job`, {
        job_id: joborder_id,
        token: document.cookie,
      });

      toast.success(`Job ${currentSaveStatus ? 'unsaved' : 'saved'} successfully`, {
        hideProgressBar: true,
        closeOnClick: true,
      });

      setSave(prevStates => ({
        ...prevStates,
        [joborder_id]: !currentSaveStatus, // Toggle the save state
      }));


      // saveStatus(prevStates => ({
      //   ...prevStates,
      //   [joborder_id]: !currentSaveStatus, // Toggle the save state
      // }));
    } catch (error) {
      console.error("Error saving/unsaving job:", error);
      // Handle error, show a toast or perform other actions as needed
    }
  };


  // console.log("getData", getData);


  return (
    <div>
      {
        getData.length > 0 &&
        (
          <div className="container d-flex justify-content-between align-items-center pb-2 fx">
            <div className="fw-bold " style={{ zIndex: "1" }}>Shortlisted Jobs ( {totalJobs} ) </div>
            <div className="fw-bold " style={{ zIndex: "1" }}>
              <Button size="small" className="fw-bold mx-2" sx={{ textTransform: "none" }} variant="text" onClick={handleCheckall} >Select Top 7 Jobs</Button>
              {countjobs === 0 ? (
                <Button
                  disabled={countjobs === 0 ? true : false}
                  size="small"
                  className="fw-bold mx-2"
                  variant="contained"
                // onClick={handleBulkapply}
                >
                  Apply to jobs
                </Button>
              ) : (
                <Button
                  disabled={countjobs === 0 ? true : false}
                  onClick={handleBulkapply}
                  size="small"
                  className="fw-bold mx-2"
                  variant="contained"
                >
                  Apply to {countjobs} {countjobs === 1 ? "job" : "jobs"}
                </Button>
              )}
            </div>
          </div>
        )
      }
      <div style={{ backgroundColor: "rgb(246, 246, 246)" }}>
        <div ref={scrollContainerRef} className='jbscrollsave'>
          <div className='py-3'>
            {loading && (
              <div className="container">
                <SavedShimmer />
              </div>
            )}
            {
              !loading && getData.length > 0 ?
                getData.map((data, index) => (
                  <div key={index} className="container">
                    <ShortListedStatus
                      data={data}
                      summit={summit}
                      isShortlisted={true}
                      countjobs={countjobs}
                      checkjobid={checkjobid}
                      setcountjobs={setcountjobs}
                      setcheckjobid={setcheckjobid}
                      save={save[data.joborder_id]}
                      onUnsave={(id) => handleUnSave(id)}
                      handleSave={(joborder_id) => handleSave(joborder_id)}
                    />
                  </div>
                ))
                :
                !loading && <Recommendedempty isShortlisted={true} />
            }
          </div>
          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShortList